angular.module('LeasePilot').service('leaseAbstractService', () => {
  function normalize(text) {
    if (typeof text === 'string') {
      return text
        .replace(/&nbsp;/g, '')
        .replace(/&#xFEFF;/g, '')
        .trim();
    }

    return text;
  }

  // ================================================================================
  // The following code was built specifically for Kite and is considered deprecated
  // ================================================================================

  // NOTE: this code is taken from download_abstract_directive.js
  const getConceptDataDeprecated = (initialConcept) => {
    const concept = initialConcept.clone();
    const data = {};
    // get concept section
    const section = concept
      .find('[list]')
      .first()
      .removeAttr('list');

    const heading1 = concept.find('[data-list-set-heading]').first().data('list-set-heading');
    data.exhibit = null;
    if (heading1 && heading1.indexOf('EXHIBIT') > -1) {
      data.exhibit = heading1.split(' ').slice(1,2)[0];
    }

    data.section = section[0] ? normalize(section[0].outerHTML) : null;

    // clean list-items markers to prevent word listing
    concept.find('[style*="display:none"]').remove();
    concept.find('[list]').removeAttr('list');

    // clean font style
    concept.find('[style*="font-family"]').css('font-family', '');
    concept.find('[style*="font-size"]').css('font-size', '');

    // get concept title or titles
    const titles = normalize(concept.find('concept-title').map((_, item) => $(item).text()).toArray());
    if (titles.length === 0) {
      data.title = concept.html();
    } else if (titles.length === 1) {
      data.title = titles[0];
    } else {
      data.titles = titles;
    }

    section.remove();

    return data;
  };

  const prepareUnstructuredDataDeprecated = () => {
    const unstructured = {};

    const root = document.querySelector('.lease--original').cloneNode(true);
    const elementsToRemove = root.querySelectorAll('.lp-hide');
    for (let removeIndex = 0; removeIndex < elementsToRemove.length; removeIndex++) {
      elementsToRemove[removeIndex].remove();
    }

    _.each($(root).find('concept[var]'), (el) => {
      const concept = $(el)
        .attr('var')
        .replace('lease.', '');
      if (!unstructured[concept]) {
        unstructured[concept] = [];
      }
      const data = getConceptDataDeprecated($(el));
      unstructured[concept].push(data);
    });

    return unstructured;
  };

  // ================================================================================
  // The following code is a generalization of extracting an abstract from a document
  // ================================================================================

  const clean = (concept) => {
    let html = concept.outerHTML;

    html = html
      .replace(/\u200B/g, '')  // ZERO WIDTH SPACE
      .replace(/\u200D/g, '')  // ZERO WIDTH JOINER
      .replace(/\ufeff/g, '');  // ZERO WIDTH NO-BREAK SPACE

    // TODO: this code was copied from `lease_editor_controller.js` and altered
    // We should refactored this code and re-use in both place
    html = html.replace(/{{.*?}}/g, '');
    html = sanitizeHtml(html, {
      allowedTags: [
        'b',
        'strong',
        'i',
        'u',
        'p',
        'span',
        'div',
        'br',
        'input',
        'img',
        'font',
        'table',
        'thead',
        'tbody',
        'tfoot',
        'colgroup',
        'col',
        'tr',
        'th',
        'td',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'crr',
        'crb',
        'sup',
        'sub',
        'ol',
        'ul',
        'li',
        'concept',
        'concept-title',
      ],
      allowedAttributes: {
        a: ['href', 'name', 'target'],
        img: ['src', 'width', 'height'],
        table: ['cellspacing', 'cellpadding', 'border'],
        td: ['colspan', 'rowspan'],
        ol: ['type'],
        'input': ['type', 'value'],
        'concept': ['*'],
        'concept-title': ['*'],
        'span': ['list'],
        '*': ['style'],
      },
      allowedSchemes: ['http', 'https'],
      allowedSchemesByTag: {
        img: ['data', 'http', 'https'],
      },
      exclusiveFilter: function(frame) {
        const text = frame.text;
        const isHidden = frame.attribs.style && frame.attribs.style.indexOf('display: none') !== -1;
        const is7pt = frame.attribs.style && frame.attribs.style.indexOf('font: 7pt') !== -1;

        if (isHidden) {
          return true;
        }
        if (is7pt) {
          return false;
        }
        if (!text) {
          return true
        }

        return false;
      },
    });

    const dummy = document.createElement('dummy');
    dummy.innerHTML = html.trim();

    const spans = Array.from(dummy.querySelectorAll('[style]'));
    spans.forEach(node => {
      if (node.style.display === 'none') {
        node.remove();
      }
      if (node.style.fontFamily) {
        node.style.fontFamily = '';
      }
      if (node.style.fontSize) {
        node.style.fontSize = '';
      }
    });

    return dummy.firstElementChild;
  };

  const getName = (concept) => {
    const name = concept.getAttribute('var');

    return name.trim();
  };

  const getSection = (concept) => {
    const list = concept.querySelector('[list]');
    let result = '';

    if (list) {
      const section = list.innerText;
      result = section.trim();
    }

    return result;
  };

  const getModified = (concept) => {
    const dirty = concept.querySelectorAll('.lp-dirty');

    return dirty.length > 0;
  };

  const getDeleted = (concept) => {
    const clone = concept.cloneNode(true);

    clone.querySelectorAll('[list]').forEach(list => {
      list.remove();
    });

    const text = clone.textContent.trim();

    return text.length === 0;
  };

  const getHTML = (concept) => {
    let html = concept.innerHTML;   

    return html.trim();
  };

  const getText = (concept) => {
    const text = concept.innerText;

    return text.trim();
  };

  const getTitles = (concept) => {
    const titles = [];

    concept.querySelectorAll('concept-title').forEach(node => {
      const text = node.innerText;
      const name = node.getAttribute('name');

      titles.push({
        text,
        name,
      });
    });

    return titles;
  };

  const process = (concept) => {
    const clone = clean(concept.cloneNode(true));
    const name = getName(clone);
    const section = getSection(clone);
    const modified = getModified(clone);
    const deleted = getDeleted(clone);
    const html = getHTML(clone);
    const text = getText(clone);
    const titles = getTitles(clone);

    return {
      name,
      section,
      modified,
      deleted,
      body: {
        html,
        text,
      },
      titles,
    };
  };

  const abstract = () => {
    const root = document.querySelector('.lease--original').cloneNode(true);
    const elementsToRemove = root.querySelectorAll('.lp-hide');
    for (let removeIndex = 0; removeIndex < elementsToRemove.length; removeIndex++) {
      elementsToRemove[removeIndex].remove();
    }

    const unstructured = [];
    const concepts = root.querySelectorAll('concept[var]');

    concepts.forEach(concept => {
      unstructured.push(process(concept));
    });

    return unstructured;
  };

  return {
    prepareUnstructuredDataDeprecated,
    abstract,
  };
});
